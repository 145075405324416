/*
These functions make sure WordPress
and Foundation play nice together.
*/

jQuery(document).ready(function() {
	
	// Remove empty P tags created by WP inside of Accordion and Orbit
	jQuery('.accordion p:empty, .orbit p:empty').remove();

	// Adds Flex Video to YouTube and Vimeo Embeds
	jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
		  jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
		  jQuery(this).wrap("<div class='responsive-embed'/>");
		}
	});  
	
	// Search field
	jQuery('.searchopen, .searchcloser').on( 'click', function() {
		if(jQuery( ".searchinner" ).is(':visible')){
			jQuery( ".searchinner" ).slideUp();
			jQuery('.searchopen').removeClass('active');
			jQuery('.searchcloser').removeClass('active');
		}else{
			jQuery( ".searchinner" ).slideDown();
			jQuery('.searchopen').addClass('active');
			jQuery('.searchcloser').addClass('active');
			jQuery("input.search-field").focus();
		}
	});



	jQuery('a').click(function (event){
		  $element= jQuery(this).attr("href");
		  $target= jQuery(this).attr("target");
		  $feath= jQuery(this).attr("data-featherlight");
		  if($element.indexOf("#") > -1) {
    
		  }
		  else if(jQuery(this).hasClass('emaillink')){
		  	
		  }
		  else if($target=="_self" || $target=="_blank"){
		  	
		  }
		  else if($feath=="ajax"){
		  	
		  }		  
		  else{
		  	event.preventDefault();
		  	jQuery('body').animate({
		    	//opacity: 0.0,
		  	}, function() {
		    	location.href = $element;
				/*jQuery('#preloader').slideDown(200,'swing'); // will fade out the white DIV that covers the website.
				jQuery('#status	').delay( 200 ).fadeIn(1200);*/
				location.href = $element;	    	
		  	});
		 }	
	});	

	jQuery(".hamburger").click(function() {
		jQuery('.hamburger').toggleClass("is-active");
	});
	
	jQuery(".js-off-canvas-overlay.is-overlay-fixed").click(function() {
		jQuery('.hamburger').toggleClass("is-active");
	});


	/*setTimeout(
		function()
		{
			jQuery('#preloader').slideUp(600); // will fade out the white DIV that covers the website.
		}, 600); */

	/*jQuery('#status	').fadeOut(600);
	jQuery('#preloader').fadeOut(600); */
}); 